<template>
	<div class="container">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': 'calc(100vh)' }">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<div class="msg-box">
					<template v-for="(item, index) in list">
						<div class="row item" v-if="item.type == 1" @click="navAgreeList(index)">
							<div class="avatar" :class="{ dot: item.is_read == 0 }"><van-image round :src="require('@/assets/msg/zan.png')" fit="cover" /></div>
							<div class="text-view-box">
								<div class="row cb msg-main">
									<div class="col text-view">
										<div class="title text-ellipsis">{{ item.data.nickname }}</div>
										<div class="content text-ellipsis">{{ item.data.describe }}</div>
									</div>
									<div class="col cc date">
										<div class="row cc num" v-if="item.data.unread_num > 0">{{ item.data.unread_num }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row item" v-else-if="item.type == 2" @click="navUserHome(index)">
							<div class="avatar" :class="{ dot: item.is_read == 0 }"><van-image round :src="item.data.avatar" fit="cover" /></div>
							<div class="text-view-box">
								<div class="row cb msg-main">
									<div class="col text-view">
										<div class="title text-ellipsis">{{ item.data.nickname }}</div>
										<div class="content text-ellipsis">{{ item.data.describe }}</div>
									</div>
									<div class="row cc sub" v-if="item.data.is_focus === '0'" @click.stop="sub(index)">回关</div>
									<div class="row cc sub disabled" v-else @click.stop="sub(index)">已关注</div>
								</div>
							</div>
						</div>
						<div class="row item" v-else-if="item.type == 3 || item.type == 4 || item.type == 5 || item.type == 7 || item.type == 8" @click="navAsk(index)">
							<div class="avatar" :class="{ dot: item.is_read == 0 }"><van-image round :src="item.data.avatar" fit="cover" /></div>
							<div class="text-view-box">
								<div class="row cb msg-main">
									<div class="col text-view">
										<div class="row cb hd">
											<div class="title text-ellipsis">{{ item.data.nickname }}</div>
											<div class="time">{{ item.create_time_text }}</div>
										</div>
										<div class="content text-ellipsis">{{ item.data.describe }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row item" v-else-if="item.type == 6" @click="navAsk(index)">
							<div class="avatar" :class="{ dot: item.is_read == 0 }"><van-image round :src="require('@/assets/msg/notice.png')" fit="cover" /></div>
							<div class="text-view-box">
								<div class="row cb msg-main">
									<div class="col text-view">
										<div class="row cb hd">
											<div class="title text-ellipsis">{{ item.data.nickname }}</div>
											<div class="time">{{ item.create_time_text }}</div>
										</div>
										<div class="content text-ellipsis">{{ item.data.describe }}</div>
									</div>
								</div>
								<div class="desc text-ellipsis-2">{{ item.data.content }}</div>
							</div>
						</div>
					</template>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
export default {
	data() {
		return {
			page: 0,
			pageSize: 20,
			list: [],
			loading: false,
			finished: false,
			refreshing: false
		};
	},
	mounted() {},
	methods: {
		navAgreeList(index) {
			this.$set(this.list[index], 'is_read', 1);
			let item = this.list[index];
			this.$service.post('user_info/readMess', { mess_id: item.id });
			this.$router.push({
				path: '/msg/agree'
			});
		},
		async sub(index) {
			this.$set(this.list[index], 'is_read', 1);
			let item = this.list[index];
			this.$service.post('user_info/readMess', { mess_id: item.id });
			if (item.data.is_focus === '0') {
				await this.$service.post('question/focusUser', { focus_user_id: item.data.user_id });
				item.data.is_focus = '1';
			} else {
				await this.$service.post('question/delFocusUser', { focus_user_id: item.data.user_id });
				item.data.is_focus = '0';
			}
		},
		navUserHome(index) {
			this.$set(this.list[index], 'is_read', 1);
			let item = this.list[index];
			this.$service.post('user_info/readMess', { mess_id: item.id });
			this.$router.push({
				path: '/user/home',
				query: { user_id: item.data.user_id }
			});
		},
		navAsk(index) {
			this.$set(this.list[index], 'is_read', 1);
			let item = this.list[index];
			this.$service.post('user_info/readMess', { mess_id: item.id });
			this.$router.push({
				path: '/ask/detail',
				query: { id: item.data.question_id }
			});
		},
		onLoad() {
			if (this.refreshing) {
				this.list = [];
				this.refreshing = false;
			} else {
				console.log('onLoad');
				this.loading = true;
				this.page++;
				this.$service
					.post('user_info/messageList', {
						page: this.page,
						pageSize: this.pageSize
					})
					.then(res => {
						let data = res.data.data;
						this.list = this.list.concat(data);
						this.loading = false;
						if (data.length < this.pageSize) {
							this.finished = true;
						}
					});
			}
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.finished = false;
			this.refreshing = true;
			this.onLoad();
		}
	}
};
</script>

<style lang="less" scoped>
@import './msg.less';
</style>
